@use '../variables/_mixins' as *
.ScrollToTop
    .top-to-btm
        position: relative

    .icon-position
        position: fixed
        bottom: 50px
        right: 30px
        z-index: 20

    .icon-style
        background-color: var(--black)
        border: 2px solid #fff
        border-radius: 50%
        height: 45px
        width: 45px
        color: #fff
        cursor: pointer
        animation: movebtn 2s ease-in-out
        transition: all .5s ease-in-out

    @keyframes movebtn
        0%
            transform: translateY(0px)

        25%
            transform: translateY(10px)

        50%
            transform: translateY(10px)

        75%
            transform: translateY(-20px)

        100%
            transform: translateY(0px)

    @include lg

        .icon-position:hover

            animation: none
            background: #fff
            color: var(--black)
            border: 2px solid var(--black)
