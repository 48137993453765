@use '../variables/_mixins' as *

.Header
    display: flex
    justify-content: flex-end
    align-items: center

    .links
        margin-top: 2em
        // padding-right: 64px
        a
            text-decoration: none
            color: white
            font-size: 1.3rem
            padding-right: 1.5em

        &:first-child

@include lg
    .Header
        justify-content: flex-start
        padding-left: 2em
        align-items: center
        .links
            a
                font-size: 2.5rem
    // font-size: 1.8rem
    // padding-bottom: 1em

        img
            width: 40px
