.Footer
    // font-size: 1.5em
    color: white
    display: flex
    justify-content: center
    align-items: center
    height: 60px
    // padding-top: 1em
    a
        color: #dbdbdb
        padding: 0 0.5em
        transition: 200ms ease-in-out
        border-radius: 45px
        width: 30px

        &:hover
            // transform: scale(1.04)
            color: white
    p
        margin: 10px
