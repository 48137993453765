@use '../variables/_mixins' as *
.Projects

    background: white
    color: black
    text-align: center
    padding: 0.3em
    .project-card
    h1
        font-size: 2rem
        padding: 1em
        padding-top: 3em
        border-bottom: 4px solid black
    h3
        font-size: 3em
        margin-top: 3em
        padding-top: 2em
        margin: auto

    p
        font-size: 1.5rem
        padding: 0
        padding-bottom: 0.8em

    .card-text
        // display: flex
        font-size: 2em

        margin-top: 50px

        .card-text-first
            height: 50%

        .card-text-second
            display: flex
            justify-content: center
            align-items: center
            width: 80%
            padding-bottom: 0.3em
            margin: 0 auto
                // flex-direction: row
            img
                width: 35px
                height: auto
                margin: 0
                padding: 8px
                padding-top: 20px
                padding-bottom: 0

    img
        height: auto
        width: 100%
        padding: 0

    .tooltip-parent
        width: 100px

    .tooltip

        position: relative
        font-size: 0.6em

@include lg
    .Projects
        h1
            padding-top: 0.5em
            border: none
        .Projects-grid
            display: grid
            grid-template-columns: 1fr 1fr
            padding: 1em

        h3
            font-size: 2em
            padding: 0

        p
            font-size: 1.5rem

        .project-card

            display: flex
            flex-direction: row
            flex-wrap: wrap
            justify-content: center
            align-items: flex-start
            padding: 25px
            margin: 20px
            border: solid 5px var(--black)
            .title
                width: 100%
                font-size: 2em
                padding-top: 0
            hr
                width: 90%
                margin: auto
                margin-top: 0
                margin-bottom: 0

            img
                padding: 20px
                transition: 300ms ease
                padding-left: 2em
                &:hover
                    transform: scale(1.05)

        .card-text
            display: flex
            font-size: 2em
            width: 700px
            margin-top: 50px
            .card-text-inner
                height: 500px
                // display: flex
                // flex-direction: column
                // justify-content: center

            .card-text-first
                display: flex
                align-items: center
                justify-content: center
            .card-text-second
                display: flex
                justify-content: center
                width: 400px
                margin: 0 auto
                height: 200px
                // flex-direction: row
                img
                    width: auto
                    height: 50px
                .image
                    display: flex
                    justify-content: flex-end
        img

            object-fit: contains

@include md
    .Projects-grid
        display: block
        grid-template-columns: 1fr
        .card-text
        img
            height: auto
@include sm
.Projects
    .Projects-grid
        display: flex
        flex-direction: column
        align-items: stretch
    .project-card
        width: 80%
        margin: auto
        margin-top: 2em
