@use '../variables/_mixins' as *
// .Main
//     text-align: center
//     padding: 1em
//     img
//         width: 100%
//     h1
//         padding-top: 1em
//     .text-ctn
//         padding-bottom: 1rem
//     .skills
//         display: flex
//         flex-direction: column
//         justify-content: flex-end
//         svg
//             font-size: 0.8em
//         h2
//             font-size: 1.5em
//             line-height: 0
//             padding-top: 2em
//     .backend-ctn
//         display: flex

//         justify-content: center
//         svg
//             font-size: 1.8em

//         .skill-ctn

//     .social-links a
//         color: #dbdbdb
//         opacity: 75%
//         transition: ease-in-out 70ms
//         &:hover
//             opacity: 100%

//     @include lg
//         display: flex
//         // padding: 2em
//         height: 83vh
//         // .backend-ctn
//         //     display: flex
//         //     margin: 0
//         //     padding: 0
//         .left-side-links
//             padding: 1.3em

//             a
//                 padding: .5em
//                 text-decoration: none
//                 color: white
//                 font-size: 2em
//         img
//             height: auto
//             width: 80%
//             margin: auto
//             margin-top: 180px
//             object-fit: cover
//             border: 5px white solid

//         h1
//             font-size: 1.4em
//             padding-bottom: 1em
//             padding-top: 0
//             line-height: 1.3

//         .text-ctn
//             font-size: 1.5em
//             padding-bottom: 1em
//             padding: 0 2em
//             margin: auto
//             width: 33%
//             margin-top: 180px

//         .skills
//             display: block
//             padding-left: 3em
//             justify-content: space-around
//             align-items: center
//             margin-top: 100px

//         .backend-ctn

//             display: flex
//             flex-direction: row
//             flex-wrap: wrap
//             justify-content: center
//             align-items: center
//             padding-top: 1.5em
//             p
//                 height: 10px

//         .skill-ctn
//             display: flex
//             flex-direction: column
//             align-items: center
//             justify-content: center
//             align-content: center
//             padding-bottom: 1.5em
//             padding: 0.5em
//             svg
//                 font-size: 2em

//         h2
//             font-size: 1.8em
//             padding: 0
//             // padding-top: 80px
//         p
//             font-size: 1.3em

//         hr

//             margin: auto
//             padding: 0
//             height: 50vh
//             // img
//             //     width: 350px
//             //     height: auto

//     .text-ctn
//         // width: 50%

//         // text-align: left
// @include sm
// .Main
//     font-size: .9em
//     padding: 2em
//     height: 100%

.Main
    display: flex
    justify-content: center
    flex-direction: column
    height: 100%
    .left-side
        img
            margin: auto
            object-fit: cover
            width: 50%
            padding: 1em 0
            height: 100%

    .middle
        text-align: center
        h1
            span
                color: #c8c8c8ee

        p
            padding-top: 1em
    .right-side
        display: flex
        justify-content: center
        flex-direction: column
        padding-top: 1em
        font-size: 0.8em

        h2
            text-align: center
            font-size: 2.5em
            padding-bottom: 0.8em

        .skills
            display: flex
            flex-direction: row
            justify-content: center
        .double-skill
            display: flex
            flex-direction: column
            .double-skill-half
                display: flex
                flex-direction: row
                .skill-ctn
                    &:first-child
                        width: 25%

        .skill-ctn
            display: flex
            flex-direction: column
            align-items: center
            margin: auto
            width: 20%
            svg
                font-size: 2em
                p
                    font-size: 0.8em

@include lg
    .Main
        flex-direction: row
        height: 80vh
        justify-content: space-between
        items-align: center
        align-items: center
        font-size: 0.8em
        padding: 4em 2em
        svg
            width: 100px
        .left-side
            display: flex
            flex-wrap: nowrap
            justify-content: center
            align-items: center
            margin: 0

            img
                width: 70%
                margin: 0
            .left-side-links
                display: flex
                flex-direction: column
                a
                    color: white
                    text-decoration: none
                    svg
                        &:hover
                            opacity: 75%

        .middle
            display: flex
            flex-direction: row-reverse
            align-items: center

            h1
                font-size: 2em
                width: 70%

            p
                font-size: 1em
        .big-left
            padding: 1em
            display: flex
        .right-side
            margin: auto
            height: 100%

            h2
                padding-top: 1em
                padding-bottom: 1em

            .double-skill
                .double-skill-half
                    &:last-child
                        .skill-ctn

                            width: 33%

            p
                font-size: 1.2em
            .skill-ctn
                width: 100%
                svg
                    font-size: 2em
