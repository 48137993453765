@use './styles/_header'
@use './styles/_footer' 
@use './styles/_main'
@use './styles/_projects'
@use './variables/_mixins'
@use './styles/_reset'
@use './styles/_scroll-to-top'
@use './styles/_mobile-menu'
@use './variables/_colors' as *

//     Font-Family: 'Questrial', Sans-Serif;
// Font-Size: 40px;

*
    scroll-behavior: smooth

body
    background: white
    padding: 1px

    p
        font-family: 'Quattrocento', serif
        font-size: 16px
        padding: 1em
    a
        font-size: 2rem
    h1,h2,h3,h4, a
        font-family: 'Oswald', sans-serif
        font-weight: normal
    h1
        font-size: 1.5rem

    h2
        font-size: 1rem
    h3
        font-size: 0.7em

    //     h1
    //         font-size: 1.2em
    //     h2
    //         font-size: 1em
    .outter-ctn
        background: var(--black)
        padding: 4px

    .App
        background: white
        padding: 4px

        // width: 100%
        // height: 100%

        .inner-ctn
            background: var(--black)
            color: white
            align-content: center
