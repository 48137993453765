.MobileMenu
    // right: 20px
    font-size: 1.3em
    .menu-icon
        cursor: pointer
        font-size: 1.8em
        padding-right: 0.6em
        padding-top: .4em

.menu-ctn
    height: 100%
    width: 100%
    color: white
    padding-top: 2em

    background-color: var(--black)
    li
        padding: 0.8em 2em
        font-size: 1.5em
        a
            text-decoration: none
            color: white

        svg
            font-size: 0.6em
            cursor: pointer
    .menu-ctn-socialmedia-icons
        display: flex
        justify-content: center
        position: absolute
        bottom: 0
        width: 100%
        padding-bottom: 1.3em
        svg
            padding: 0.8em
            font-size: 1.5em
